import React from 'react';
import { Typography,  Container, Paper } from '@mui/material';

const Education = () => {
    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>Education</Typography>
                <Typography variant="body1">
                    Master of Science in Computer Science
                    <br />
                    (In progress online on weekends only; expected completion 10/2025)
                    <br />
                    Maharishi International University, Fairfield, Iowa
                </Typography>
                <br />
                <br />
                <Typography variant="body1">
                    Bachelors in Software Engineering
                    <br />
                    COMSATS University Islamabad, Pakistan (07/2018)
                </Typography>
            </Paper>
        </Container>
    );
};

export default Education;
