import React from 'react';
import { Typography, Container, Paper, List, ListItem, ListItemText, Grid } from '@mui/material';

const Skills = () => {
    const frontendSkills = ['HTML', 'JavaScript'];
    const backendSkills = ['.NET', 'Express'];
    const otherSkills = ['Git', 'RESTful APIs'];

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>Skills</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>Frontend Skills:</Typography>
                        <List>
                            {frontendSkills.map((skill) => (
                                <ListItem key={skill}>
                                    <ListItemText primary={skill} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>Backend Skills:</Typography>
                        <List>
                            {backendSkills.map((skill) => (
                                <ListItem key={skill}>
                                    <ListItemText primary={skill} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>Other Skills:</Typography>
                        <List>
                            {otherSkills.map((skill) => (
                                <ListItem key={skill}>
                                    <ListItemText primary={skill} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default Skills;
