import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const Job = ({ title, company, duration, location, description, skills }) => (
    <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>{title}</Typography>
        <Typography variant="body2" color="textSecondary">{company}</Typography>
        <Typography variant="body2" color="textSecondary">{duration}</Typography>
        <Typography variant="body2" color="textSecondary">{location}</Typography>
        <List>
            {description.map((item, index) => (
                <ListItem key={index}>
                    <ListItemText primary={item} />
                </ListItem>
            ))}
        </List>
        <Typography variant="body2" color="textSecondary">{skills}</Typography>
    </Box>
);

const WorkExperience = () => {
    const jobs = [
        {
            title: 'Fullstack .NET Developer',
            company: 'Stuller, Inc.',
            duration: 'Oct 2023 - Present',
            location: 'Lafayette, Louisiana, United States',
            description: ['Developing and maintaining scalable web applications using .NET and React.js.', 'Collaborating with cross-functional teams to deliver high-quality software solutions.', 'Implementing best practices for code quality, security, and performance.'],
            skills: 'C#, .NET, React.js, Docker, RESTful APIs, Microservices',
        },
        {
            title: 'Software Engineer',
            company: 'Pakistan Single Window (PSW)',
            duration: 'Jun 2021 - Jan 2023',
            location: 'Karachi, Sindh, Pakistan',
            description: [
                'Engineered and deployed .Net Core microservices in Docker containers, achieving seamless RabbitMQ communication.',
                'Served as the Lead Developer for the Lab Management System (LMS), a pivotal tool for real-time monitoring of test reports and compliance statuses. This system is integral to risk management processes for Pakistan Customs and other regulatory bodies, achieving a reduction in overall process time by over 90%.',
                'Spearheaded the Subscription, Reporting, and User Access features as a Co-developer for the User Management System. This system is currently utilized by over 10 government bodies and is slated for adoption by 75 more. The initiative has streamlined cross-border trade activities by digitalizing and centralizing user data, aligning with broader governmental objectives.',
                'Devised RESTful API services secured with JWT and OAuth 2.0.',
            ],
            skills: '.Net Core, Docker, RabbitMQ, React.js, Scrum, JWT, OAuth 2.0',
        },
        {
            title: 'Freelance Software Developer',
            company: 'Self-employed',
            duration: 'Aug 2020 - Jun 2021',
            location: 'Karachi, Sindh, Pakistan',
            description: [
                'Participated in the agile development process of LinkedIn Clone with its core features made with React.js and Node.js using Express framework.',
                'Fabricated a Netflix clone tailored for local streaming, including login and video playback features.',
            ],
            skills: 'React.js, Node.js, Express, MySQL',
        },
        {
            title: 'Software Developer',
            company: 'Bari\'s Technology Solutions',
            duration: 'Dec 2019 - Aug 2020',
            location: 'Karachi, Sindh, Pakistan',
            description: [
                'Identified and resolved a long-standing bottleneck in legacy C# libraries, resulting in a 30% reduction in API request execution time. This breakthrough improvement impacted over 400 shipment firms worldwide, streamlining the generation of hundreds of thousands of labels per company per day.',
                'Swiftly delivered a shipment solution for Fastway Australia, trimming API request processing time by 55%.',
                'Provided critical maintenance and support for more than 20 global shipment solutions, including industry leaders like UPS, DHL, and USPS. This role ensured the seamless continuity of millions of successful shipment operations worldwide, reinforcing the reliability and efficiency of these global networks.',
                'Conducted extensive unit testing to achieve module perfection, resulting in over 95% test coverage. Implemented several hundred test cases for a single module, setting a new standard for code quality and reliability within the team.',
            ],
            skills: 'C#, .Net Standard, Unit Testing',
        },
        {
            title: 'Analyst Software Engineer',
            company: 'TEXT Private Limited',
            duration: 'Dec 2018 - Oct 2019',
            location: 'Karachi, Sindh, Pakistan',
            description: [
                'Orchestrated .Net and PostgreSQL solutions for projects funded by the Bill & Melinda Gates Foundation.',
                'Created Live-Unilever portals using jQuery, .Net, and MSSQL, enabling instant broadcasting of texts, polls, images, and videos.',
                'Amplified system efficiency by dramatically reducing page load times by 65% and liberating 450GB of database storage.',
                'Upgraded and redesigned a PostgreSQL database with 110 tables, enhancing functionality and performance.',
            ],
            skills: 'C#, .Net Framework, JavaScript, PostgreSQL',
        }
    ];

    return (
        <div>
            <Typography variant="h4" gutterBottom>Experience</Typography>
            {jobs.map((job, index) => (
                <Job key={index} {...job} />
            ))}
        </div>
    );
};

export default WorkExperience;
