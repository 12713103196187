import React from 'react';
import { Typography, Container } from '@mui/material';

const Intro = () => {
  return (
    <Container maxWidth="md" sx={{ my: 4, textAlign: 'center' }}>
      <Typography variant="h2" gutterBottom>Welcome to My Website</Typography>
      <Typography variant="h5" gutterBottom>I am Syed Dawood, a Fullstack Developer.</Typography>
    </Container>
  );
}

export default Intro;
