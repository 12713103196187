import React from 'react';
import { Container, Box, Grid, Paper } from '@mui/material';
import Intro from './Intro';
import About from './About';
import Education from './Education';
import Skills from './Skills';
import WorkExperience from './WorkExperience';
import Blog from './Blog';
import ContactForm from './ContactForm';

const HomePage = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Intro />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <About />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Education />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Skills />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <WorkExperience />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Blog />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <ContactForm />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomePage;
