import React from 'react';
import { Typography, Container, Paper } from '@mui/material';

const About = () => {
    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>About Me</Typography>
                <Typography variant="body1" paragraph>
                    Hi, I am Syed Dawood, a skilled Fullstack .NET Developer with a strong background in creating scalable and efficient software architectures. I am committed to lifelong learning, community mentorship, and contributing to cutting-edge technology solutions.
                </Typography>
                <Typography variant="body1" paragraph>
                    Throughout my career, I have developed expertise in a variety of technologies including C#, .Net, .Net Core, Docker, React.js/Redux, and JavaScript. I have extensive experience in web development using frameworks and libraries like Typescript, Node, Express, Bootstrap, and more.
                </Typography>
                <Typography variant="body1" paragraph>
                    My professional journey includes significant roles such as Software Engineer at Pakistan Single Window, where I engineered and deployed .Net Core microservices in Docker containers, and served as the Lead Developer for the Lab Management System (LMS). I also co-developed the User Management System used by multiple government bodies.
                </Typography>
                <Typography variant="body1" paragraph>
                    At Bari's Technology Solutions, I resolved critical bottlenecks in legacy C# libraries and delivered shipment solutions that improved API request processing times significantly. My role ensured the seamless continuity of millions of successful shipment operations worldwide.
                </Typography>
                <Typography variant="body1" paragraph>
                    My experience as an Analyst Software Engineer at TEXT Private Limited involved creating solutions for projects funded by the Bill & Melinda Gates Foundation and amplifying system efficiency for large-scale databases.
                </Typography>
                <Typography variant="body1" paragraph>
                    I have also undertaken various personal and freelance projects, including developing a LinkedIn clone using React.js and Node.js, and a Netflix clone for local streaming. My academic projects at Maharishi International University include a role-based property management system similar to Zillow, developed using Java Spring Boot and React.js.
                </Typography>
                <Typography variant="body1" paragraph>
                    I am currently pursuing a Master of Science in Computer Science at Maharishi International University, with key courses in modern programming principles, web application programming, algorithms, web application architecture, and advanced software development.
                </Typography>
            </Paper>
        </Container>
    );
};

export default About;
